import React, { Fragment } from 'react'

import cx from 'classnames'

import { Link, FormattedMessage } from 'gatsby-plugin-intl'

import {
  contact_details,
  contact_phone,
} from '../layouts/styles.module.css'

const FormattedMessageValues: Record<string, any> = {
  strong: (str: string) => <strong>{str}</strong>,
  span: (str: string) => <span>{str}</span>,
  p: (str: string) => <p>{str}</p>,

  br: <br />,

  lcu: (str: string) => <Link to='/contact-us'>{str}</Link>,
  lcf: (str: string) => <Link to='/contact-us#contact-form'>{str}</Link>,
}

type Props = { showMessageLink?: boolean, noPhone?: boolean }

const ContactDetails = ({ showMessageLink, noPhone }: Props): React.ReactNode => (
  <div className={cx(contact_details)}>
    <br />
    <h3>Sydney:</h3>
    <p>
      {
        !noPhone
          ? (
            <Fragment>
              <a href='tel:1800 536 363' className={cx(contact_phone)}>✆ 1800 KEMENE</a>
              <br />
              <br />
            </Fragment>
          )
          : null
      }
      11 York St
      <br />
      Sydney, NSW 2000
      <br />
      Australia
    </p>
    <h3>Hamburg:</h3>
    <p>
      Hermannstraße 13
      <br />
      20095 Hamburg,
      <br />
      Germany
      <br />
    </p>
    <br />
    {
      showMessageLink
        ? (<FormattedMessage id='contact-us.send-a-message' values={FormattedMessageValues} />)
        : null
    }
  </div>
)

export default ContactDetails 
