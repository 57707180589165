import React from 'react'
import cx from 'classnames'

import { motion, AnimatePresence } from 'framer-motion'

import {
  square_container,
  square_row,
  square_column,
  square_box,
  square_box_3,
  square_box_4,
} from './styles.module.css'


import SEO from '../components/seo'

import CookieMonster from '../components/cookiemonster'
import { PopoverContextProvider } from '../components/popover'

import Nav, { NavContextProvider } from './nav'
import Footer from './footer'


type Props = {
  children: React.ReactNode,
  path?: string,
  location: any,
}

const Layout = ({ children, location }: Props): React.ReactNode => (
  <PopoverContextProvider>
    <SEO />
    <CookieMonster />
    <NavContextProvider>
      <Nav />
      <AnimatePresence mode='popLayout'>
        <motion.main
          key={location.pathname}
          initial={{ opacity: 0, scale: 1 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          transition={{
            duration: 0.1,
          }}
        >
          {children}
        </motion.main>
      </AnimatePresence>
      <Footer />
    </NavContextProvider>
  </PopoverContextProvider>
)


type SquareProps = { children?: React.ReactNode, className?: any }

export const SquareContainer = ({ children, className }: SquareProps): React.ReactNode => (
  <div className={cx(square_container, className)}>{children}</div>
)

export const SquareRow = ({ children }: { children?: React.ReactNode }): React.ReactNode => (
  <div className={cx(square_row)}>{children}</div>
)

export const SquareColumn = ({ children }: { children?: React.ReactNode }): React.ReactNode => (
  <div className={cx(square_column)}>{children}</div>
)


type BoxProps = { children?: React.ReactNode, className?: any, small?: boolean, mid?: boolean, id?: string }
export const SquareBox = ({ children, className, small, mid, ...rest }: BoxProps): React.ReactNode => (
  <div className={cx(square_box, small && square_box_4, mid && square_box_3, className)} {...rest}><div>{children}</div></div>
)

export default Layout;
