import React from 'react';
import Flag from 'react-flagkit'
import { useLocation } from "@reach/router"

import { IntlContextConsumer, Link } from 'gatsby-plugin-intl'

import cx from 'classnames'
import { language_selector } from '../layouts/styles.module.css'

const languageName: Record<string, string> = {
  en: 'English',
  de: 'Deutsch',
}

const languageFlags: Record<string, string> = {
  en: 'AU',
  de: 'DE',
}

type Props = {
  text?: boolean
  code?: boolean
  flag?: boolean

  onSelect?: (e: React.MouseEvent) => null
}

const LangSelector = (props: Props): React.ReactNode => {

  const {
    text = false,
    code = false,
    flag = true,
    onSelect = (_: React.MouseEvent) => null,
  } = props

  const { search } = useLocation()

  return (
    <div className={cx(language_selector)}>
      <IntlContextConsumer>
        {({ languages, language, originalPath }) =>
          languages.map(lang => {

            if (lang == language) return null;
            return (
              // @ts-ignore
              <Link key={lang} to={`${originalPath}${search}`} language={lang} onClick={onSelect}>
                {flag && <Flag country={languageFlags[lang]} size={14} />}
                {code && lang.toUpperCase()}
                {text && languageName[lang]}
              </Link >
            )
          })

        }
      </IntlContextConsumer>
    </div>
  )
}

export default LangSelector
